import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { MovementService } from 'src/app/services/movement.service';
import { TraceabilityService } from 'src/app/services/traceability.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Place } from 'src/app/interfaces/place';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dados-extrato',
  templateUrl: './dados-extrato.component.html',
  styleUrls: ['./dados-extrato.component.scss']
})
export class DadosExtratoComponent implements OnInit {

  formFilter !: FormGroup;
  userId?: string | null = null;
  farmList: any[] = [];
  trackableItems: TrackableItem[] = [];
  talhaoList: any = [];
  harvestList: any[] = [];
  hashOrigins: any[] = [];
  farmSwicthed: any;
  trackableItem?: TrackableItem;
  culturesList: any[] = [];
  producer?: Enterprise;
  farms: any = [];
  producers: any;
  movementsList: any[] = [];
  producersList: any;
  isLoading: boolean = false;
  isShowingModalDadosBaixa: boolean = false;
  selectedMovements: any;
  firstEnterprise: any;
  loadingCount = 0;
  tableActive = false;
  trackableItensList: any;

  constructor(
    private location: Location,
    private router: Router,
    private enterpriseService: EnterpriseService,
    private traceabilityService: TraceabilityService,
    private movementeService: MovementService,
  ) {
    this.formFilter = new FormGroup({
      producer: new FormControl(''),
      farm: new FormControl(''),
      harvest: new FormControl(''),
      culture: new FormControl(''),
      talhao: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.getProducer();
    this.userId = sessionStorage.getItem('customerId');

    if (!this.userId) {
      this.userId = sessionStorage.getItem('customerId');
    }
    localStorage.setItem('traceId', this.userId as string);
    this.isLoading = true;
    this.enterpriseService.getEnterpriseByCustomerId(this.userId).subscribe(item => {
      this.isLoading = false;
      this.firstEnterprise = item[0];
      if (this.firstEnterprise.economicGroup) {
        this.isLoading = true;
        this.enterpriseService.getDatasGroupByEnterpriseCode(this.firstEnterprise.economicGroup).subscribe(res => {
          this.isLoading = false;
          this.producersList = res;
        }, error => {
          this.isLoading = false;
        })
      }
      sessionStorage.setItem('customerId', this.firstEnterprise.customerId);
    }, error => {
      this.isLoading = false;
    });
  }

  getProducer() {
    const customerId = sessionStorage.getItem('customerId');
    this.enterpriseService.getEnterpriseByCustomerId(customerId).subscribe(res => {
      if (res && res.length > 0) {
        this.producer = res[0];
        sessionStorage.setItem('economicGroup', this.producer.economicGroup);
        sessionStorage.setItem('document', this.producer.document);
      }
    })
  }

  onChangeProducer() {
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');
    this.tableActive = false;


    const producer = this.producersList.find((item: any) => item.id === this.formFilter.get('producer')?.value);
    this.isLoading = true;
    this.traceabilityService.getTraceByDocument(producer.document).subscribe(res => {
      this.isLoading = false;
      this.trackableItensList = res;

      this.trackableItensList?.forEach((item: any) => {
        const culture = item.item && (this.getFeatureByName(item.item, 'CULTURA')) ? this.getFeatureByName(item.item, 'CULTURA') : '';
        if (culture) {
          if (!this.culturesList.includes(culture)) {
            this.culturesList.push(culture);
          }
        }
        const harvest = item.item ? this.getFeatureByName(item.item, 'ANO_SAFRA') || this.getFeatureByName(item.item.product, 'Ano Safra') : '';
        if (harvest && !this.harvestList.includes(harvest)) {
          this.harvestList.push(harvest);
        }

        const talhao = this.getFeatureByName(item.item, 'NOME_TALHAO');
        if (talhao) {
          this.talhaoList.push(talhao);
        }

      });
      this.submitForm();
    }, () => {
      this.isLoading = false;
    })
    this.farmList = [];

    if (producer) {
      producer.places.forEach((element: any) => {
        if (element.type === 'FARM') {
          if (!this.farmList.some((farm: any) => farm.code === element.code)) {
            this.farmList.push(element);
          }
        }
      });
    }
  }

  async onFarmSelect() {
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');
    this.tableActive = false;
    this.farmSwicthed = this.farmList.find((farm: any) => farm.code === this.formFilter.get('farm')?.value);

    const producer = this.producersList.filter((item: any) => item.id === this.formFilter.get('producer')?.value);

    if (this.formFilter.get('farm')?.value) {
      this.isLoading = true;
      const item = await this.traceabilityService.getTraceByPlaceCode(this.farmSwicthed.code, producer[0].economicGroup).toPromise();
      this.isLoading = false;
      this.trackableItensList = item;
      // this.talhaoList = [];
      // this.harvestList = [];
      this.culturesList = [];

      // item?.forEach(movement => {
      //   const harvest = movement.item ? this.getFeatureByName(movement.item, 'ANO_SAFRA') || this.getFeatureByName(movement.item.product, 'Ano Safra') : '';
      //   if (harvest && !this.harvestList.includes(harvest)) {
      //     this.harvestList.push(harvest);
      //   }
      // });
    }
  }

  async onChangeCropYear() {
    // this.culturesList = [];
    this.tableActive = false;
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');

    // const selected = this.formFilter.get("harvest")?.value;
    // this.trackableItensList?.forEach((item: any) => {
    //   const feature: any = this.getFeatureByName(item.item, "ANO_SAFRA")
    //   if (feature === selected) {
    //     const culture = item.item && (this.getFeatureByName(item.item, 'CULTURA')) ? this.getFeatureByName(item.item, 'CULTURA') : '';
    //     if (culture) {
    //       if (!this.culturesList.includes(culture)) {
    //         this.culturesList.push(culture);
    //       }
    //     }
    //   }
    // });
  }

  onChangeCulture() {
    this.formFilter.get('talhao')?.setValue('');
    // this.talhaoList = [];
    this.tableActive = false;
    const culture = this.formFilter.get("culture")?.value;
    const harvest = this.formFilter.get('harvest')?.value;

    // this.trackableItensList?.forEach((item: any) => {
    //   if (culture === this.getFeatureByName(item.item, "CULTURA") && harvest === this.getFeatureByName(item.item, "ANO_SAFRA")) {
    //     const talhao = this.getFeatureByName(item.item, 'NOME_TALHAO');

    //     if (talhao) {
    //       this.talhaoList.push(talhao);
    //     }
    //   }
    // });
  }

  formatDate(dateReceveid: string): string {
    const data = new Date(dateReceveid);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  handleBackButton() {
    this.location.back();
  }

  cleanFilter() {
    this.formFilter.get('producer')?.setValue('');
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');
    this.tableActive = false;
    this.farmSwicthed = "";
  }

  submitForm() {
    this.trackableItems = [];

    if (this.formFilter.valid) {
      const selectedTalhao = this.formFilter.get('talhao')?.value;
      const selectedSafra = this.formFilter.get('harvest')?.value;
      const selectedCultura = this.formFilter.get('culture')?.value;

      this.trackableItems = this.trackableItensList;
      if (selectedTalhao) {
        this.trackableItems = this.trackableItems.filter((trackable: any) => this.getFeatureByName(trackable.item, 'NOME_TALHAO') === selectedTalhao );
      }
      if (selectedSafra) {
        this.trackableItems = this.trackableItems.filter((trackable: any) =>
          this.getFeatureByName(trackable.item, 'ANO_SAFRA') === selectedSafra);
      }
      if (selectedCultura) {
        this.trackableItems = this.trackableItems.filter((trackable: any) => this.getFeatureByName(trackable.item, 'CULTURA') === selectedCultura);
      }
      this.tableActive = true;


    }
  }

  getMovements(item: any) {
    this.movementsList = [];
    this.isLoading = true;
    this.movementeService.getMovementByToken(item.hash).subscribe(res => {
      if (res && res.movements.length > 0) {
        res.movements.forEach(movement => {
          this.movementsList.push(movement);
        });
      }

      this.handleClickOpenModal(item);

    }, error => {

    })
  }

  onChangeTalhao() {
    this.tableActive = false;
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: '-',
      usage: ''
    };

    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }

    return returnFeature.value;
  }

  closeModal(event: any) {
    if (event === 'close') {
      this.isShowingModalDadosBaixa = false;
    } else {
      this.hashOrigins = event;
      this.isShowingModalDadosBaixa = false;
    }
  }

  exportToExcel(): void {
    const trackableItems = this.trackableItems.map(item => {
      return {
        'Safra': this.getFeatureByName(item.item, 'ANO_SAFRA'),
        'Cultura': this.getFeatureByName(item.item, 'CULTURA'),
        'Fazenda': this.getFarmName(item) || '-',
        'Talhão': this.getFeatureByName(item.item, 'NOME_TALHAO'),
        'Saldo Colhido': item.item.receivedQuantity,
        'Saldo Vendido': this.getSoldBalance(item.item.receivedQuantity, item.item.amount),
        'Saldo Disponível': item.item.amount,
        'Token': item.hash,
        'Data Token': this.formatDate(item.creationDate),
      }
    });

    const movements = this.movementsList.map(movement => {
      return {
        'Token Origem': movement.origin.hash,
        'Data Venda': movement.movementDate,
        'Trader': movement.customer.document,
        'Volume': movement.amount,
        'Token baixa': movement.hash
      }
    })

    const worksheet1 = XLSX.utils.json_to_sheet(trackableItems);
    const worksheet2 = XLSX.utils.json_to_sheet(movements);

    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Produção': worksheet1,
        'Baixas': worksheet2
      },
      SheetNames: ['Produção', 'Baixas']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Dados de Extrato');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}.xlsx`);
  }

  getFarmName(element: any) {
    const placeHash = element.item.place;
    const producer = this.producersList.find((item: any) => item.id === this.formFilter.get('producer')?.value);
    const place = producer?.places.find((place: any) => place.subPlaces.some((subPlace: any) => subPlace.code === placeHash));
    return place?.nickname || '-';
  }

  handleClickOpenModal(element: any) {
    if (this.movementsList) {
      this.trackableItem = element;
      const movements = this.movementsList;
      this.selectedMovements = movements;
    }
    this.isShowingModalDadosBaixa = true;
    this.isLoading = false;
  }

  getSoldBalance(collectedBalance: number, availableBalance: number) {
    let sum = collectedBalance - availableBalance;
    return sum;
  }
}
