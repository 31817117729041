<div class="lancar-recebimento-content">
  <p class="name-page">Extrato de Saída de Volume / Dados de Extrato</p>
  <h1><span class="arrow" (click)="handleBackButton()">←</span> Dados de Extrato </h1>

  <p class="sentence">
    Consulte abaixo seu extrato de tokens registrados na plataforma e confira o saldo disponível de seus talhões.
  </p>

  <button class="button" type="button" (click)="exportToExcel()">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
        fill="#00698F" />
    </svg>
    <span>Download da Planilha</span>
  </button>

  <form class="form-search" [formGroup]="formFilter" style="display: flex">
    <div class="search-container">
      <select (change)="onChangeProducer()" class="input-search form-input" formControlName="producer"
        [style]="formFilter.get('producer')?.touched && formFilter.get('producer')?.invalid ? 'borderColor:red' : ''"
        required>
        <option value="" [selected]="true" disabled></option>
        <option *ngFor="let producer of producersList" [value]="producer.id">
          {{ producer.corporateName }}
        </option>
      </select>

      <label style="color: #585858" class="form-label"> Produtor </label>
    </div>

    <div class="search-container">
      <select formControlName="farm" (change)="onFarmSelect()" class="input-search form-input"
        [style]="formFilter.get('farm')?.touched && formFilter.get('farm')?.invalid ? 'borderColor:red' : ''">
        <option value="" [selected]="true" disabled></option>
        <option *ngFor="let farm of farmList" [value]="farm.code"> {{ farm.nickname }}</option>
      </select>
      <label style="color:#585858" class="form-label">
        Fazenda
      </label>
    </div>

    <div class="search-container">
      <select class="input-search form-input" (change)="onChangeCropYear()" formControlName="harvest"
        [style]="formFilter.get('harvest')?.touched && formFilter.get('harvest')?.invalid ? 'borderColor:red' : ''">
        <option value="" [selected]="true"></option>
        <option *ngFor="let harvest of harvestList" [value]="harvest">{{ harvest }}</option>
      </select>

      <label style="color:#585858" class="form-label">
        Safra
      </label>
    </div>

    <div class="search-container">
      <select (change)="onChangeCulture()" class="input-search form-input" formControlName="culture"
        [style]="formFilter.get('culture')?.touched && formFilter.get('culture')?.invalid ? 'borderColor:red' : ''">
        <option value="" [selected]="true"></option>
        <option *ngFor="let culture of culturesList" [value]="culture">{{culture}}</option>
      </select>

      <label style="color:#585858" class="form-label">
        Cultura
      </label>
    </div>

    <div class="search-container">
      <select class="input-search form-input" formControlName="talhao" (change)="onChangeTalhao()"
        [style]="formFilter.get('talhao')?.touched && formFilter.get('talhao')?.invalid ? 'borderColor:red' : ''">
        <option value="" [selected]="true"></option>
        <option *ngFor="let talhao of talhaoList" [value]="talhao">{{ talhao }}</option>
      </select>

      <label style="color:#585858" class="form-label">
        Talhão
      </label>
    </div>

    <div style="display:flex">
      <button type="button" (click)="submitForm()" class="btn">
        Consultar
      </button>
      <button type="button" style="margin-left: 5px;margin-bottom: 10px;"
        class="btn" (click)="cleanFilter()">
        Limpar filtros
      </button>
    </div>

  </form>

  <div class="main-area" *ngIf="tableActive">
    <div class="table-area">
      <table>
        <thead>
          <tr>
            <th>Safra</th>
            <th>Cultura</th>
            <th>Fazenda</th>
            <th>Talhão</th>
            <th>Saldo Colhido</th>
            <th>Saldo Vendido</th>
            <th>Saldo Disponível</th>
            <th>Token</th>
            <th>Data Token</th>
            <th>Pegada Talhão</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let trackable of trackableItems, index as i">
            <td>{{ (trackable.item | feature:'ANO_SAFRA') }}</td>
            <td> {{ trackable.item | feature:'CULTURA' }} </td>
            <td>{{ getFarmName(trackable) }}</td>
            <td>
              {{ trackable.item | feature:'NOME_TALHAO' }}
            </td>
            <td>
              {{ trackable.item.receivedQuantity | number: '1.0-2'}}
            </td>
            <td>
              {{ ( getSoldBalance(trackable.item.receivedQuantity, trackable.item.amount) || 0) | number: '1.0-2' }}
            </td>
            <td>
              {{ trackable.item.amount | number: '1.0-2' }}
            </td>
            <td>
              {{ trackable.hash }}
            </td>
            <td>
              {{ (trackable.creationDate | date : 'dd/MM/yyy') || '-' }}
            </td>
            <td>
              {{ (trackable.item | feature:'QTD_TOTAL_EMISSÃO_CARBONO' || 0) | number: '1.0-2' }}
            </td>
            <td>
              <button type="button" (click)="getMovements(trackable)">
                <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 2.00391C14.79 2.00391 18.17 4.13391 19.82 7.50391C18.17 10.8739 14.79 13.0039 11 13.0039C7.21 13.0039 3.83 10.8739 2.18 7.50391C3.83 4.13391 7.21 2.00391 11 2.00391ZM11 0.00390625C6 0.00390625 1.73 3.11391 0 7.50391C1.73 11.8939 6 15.0039 11 15.0039C16 15.0039 20.27 11.8939 22 7.50391C20.27 3.11391 16 0.00390625 11 0.00390625ZM11 5.00391C12.38 5.00391 13.5 6.12391 13.5 7.50391C13.5 8.88391 12.38 10.0039 11 10.0039C9.62 10.0039 8.5 8.88391 8.5 7.50391C8.5 6.12391 9.62 5.00391 11 5.00391ZM11 3.00391C8.52 3.00391 6.5 5.02391 6.5 7.50391C6.5 9.98391 8.52 12.0039 11 12.0039C13.48 12.0039 15.5 9.98391 15.5 7.50391C15.5 5.02391 13.48 3.00391 11 3.00391Z"
                    fill="black" fill-opacity="0.38" />
                </svg>
              </button>
            </td>
          </tr>
          <tr *ngIf="trackableItems.length === 0">
            <td colspan="9" style="text-align: center; width: 100vw; max-width: inherit;">Nenhum Resultado Encontrado
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-modal-dados-baixa *ngIf="isShowingModalDadosBaixa" [trackable]="trackableItem" [producer]="producer"
  [movements]="selectedMovements" (close)="closeModal($event)"></app-modal-dados-baixa>

<app-spinner *ngIf="isLoading"></app-spinner>
